<template>
    <MusinsaMB v-if="layout == 4" />
    <MusinsaPc v-else />
</template>

<script>
    import MusinsaMB from "@/pages/mb/home/Musinsa";
    import MusinsaPc from "@/pages/pc/home/Musinsa";

    export default {
    name: "musinsa",
    components: {
        MusinsaMB,
        MusinsaPc,
    },
    };
</script>
<template>
    <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">
        <Content />
    </div>
</template>

<script>
    import Content from "@/components/musinsa/ContentPage";

    export default {
        components: {
            Content
        }
    };
</script>
<template>
    <Content />
</template>

<script>
    import Content from "@/components/musinsa/ContentPage";

    export default {
        components: {
            Content
        }
    };
</script>
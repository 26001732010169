<template>
    <div class="musinsa-page">
        <section class="musinsa_main_banner">
            <a href="https://globalmusinsaapp.onelink.me/8oYc/ypr8caul" target="_blank">
                <picture>
                    <source media="(max-width: 650px)" srcset="https://dosi-in.com/images/assets/musinsa/banner-mb.png">
                    <img src="https://dosi-in.com/images/assets/musinsa/banner.png" alt="banner" width="100%" style="display:block ;">
                </picture>
                
            </a>
        </section>

        <section class="musinsa_about">
            <div class="musinsa_about-max-w">
                <h3>giới thiệu Musinsa</h3>
                <p>Là một đối tác chính thức của hầu hết các thương hiệu thời trang Hàn Quốc thời thượng và đầy cảm hứng, MUSINSA mang đến trải nghiệm hấp dẫn cho khách hàng mọi giới tính và lứa tuổi với hàng loạt các thương hiệu và phong cách thời trang được tuyển chọn.
                </p>
                <h2>BLACK FRIDAY ưu đãi khuyến mãi</h2>
            </div>
        </section>
        
        <section class="musinsa_banner_sale">
            <a href="https://globalmusinsaapp.onelink.me/8oYc/ypr8caul" target="_blank">
                <picture>
                    <source media="(max-width: 650px)" srcset="https://dosi-in.com/images/assets/musinsa/banner-1-mb.png">
                    <img src="https://dosi-in.com/images/assets/musinsa/banner-1.png" alt="banner" width="100%" style="display:block ;">
                </picture>
            </a>
        </section>

        <section class="musinsa_product">
            <div class="musinsa_content">
                <div class="content-1">
                    MUSINSA với chương trình giảm giá lớn nhất!  <span style="color:#03FF6C;">Giảm đến 80%  + GIẢM THÊM 11%</span> Bây giờ bạn đã có thể sở hữu sản phẩm mà bạn yêu thích với giá ưu đãi đặc biệt 
                </div>
                <div class="content-2">
                    mã ưu đãi: <span style="color:#03FF6C;">BLACK11FW22</span>  
                </div>
            </div>
            <div class="musinsa_product-list">
                <div>
                    <a href="https://global.musinsa.com/vn/brands/sinoon?sortCode=DISCOUNT_RATE&utm_source=dosiin&utm_medium=banner&utm_campaign=blackfriday&utm_content=brand5_sinoon" target="_blank" rel="noopener noreferrer">
                        <img src="https://dosi-in.com/images/assets/musinsa/product-1.png" alt="product">
                        <div class="name-product">
                            sinoon
                        </div> 
                        <div class="percent">
                            ~80%
                        </div>
                    </a>
                </div>
                <div>
                    <a href="https://global.musinsa.com/vn/brands/physicaleducation?sortCode=DISCOUNT_RATE&utm_source=dosiin&utm_medium=banner&utm_campaign=blackfriday&utm_content=brand2_physicaleducation" target="_blank" rel="noopener noreferrer">
                        <img src="https://dosi-in.com/images/assets/musinsa/product-2.png" alt="product">
                        <div class="name-product">
                            PHYPS
                        </div> 
                        <div class="percent">
                            ~70%
                        </div>
                    </a>
                </div>
                <div>
                    <a href="https://global.musinsa.com/vn/brands/mahagrid?sortCode=DISCOUNT_RATE&utm_source=dosiin&utm_medium=banner&utm_campaign=blackfriday&utm_content=brand3_mahagrid" target="_blank" rel="noopener noreferrer">
                        <img src="https://dosi-in.com/images/assets/musinsa/product-3.png" alt="product">
                        <div class="name-product">
                            mahagrid
                        </div> 
                        <div class="percent">
                            ~60%
                        </div>
                    </a>
                </div>
                <div>
                    <a href="https://global.musinsa.com/vn/brands/thisisneverthat?sortCode=DISCOUNT_RATE&utm_source=dosiin&utm_medium=banner&utm_campaign=blackfriday&utm_content=brand1_thisisneverthat" target="_blank" rel="noopener noreferrer">
                        <img src="https://dosi-in.com/images/assets/musinsa/product-4.png" alt="product">
                        <div class="name-product">
                            THISISNEVERTHAT
                        </div> 
                        <div class="percent">
                            ~50%
                        </div>
                    </a>
                </div>
                <div>
                    <a href="https://global.musinsa.com/vn/brands/thisisneverthat?sortCode=DISCOUNT_RATE&utm_source=dosiin&utm_medium=banner&utm_campaign=blackfriday&utm_content=brand1_thisisneverthat" target="_blank" rel="noopener noreferrer">
                        <img src="https://dosi-in.com/images/assets/musinsa/product-5.png" alt="product">
                        <div class="name-product">
                            NASTY FAnCYCLUB
                        </div> 
                        <div class="percent">
                            ~50%
                        </div>
                    </a>
                </div>
                <div>
                    <a href="https://global.musinsa.com/vn/brands/monclos?sortCode=DISCOUNT_RATE&utm_source=dosiin&utm_medium=banner&utm_campaign=blackfriday&utm_content=brand8_monclos" target="_blank" rel="noopener noreferrer">
                        <img src="https://dosi-in.com/images/assets/musinsa/product-6.png" alt="product">
                        <div class="name-product">
                            MONCLOS
                        </div> 
                        <div class="percent">
                            ~40%
                        </div>
                    </a>
                </div>
                <div>
                    <a href="https://global.musinsa.com/vn/brands/codegraphy?sortCode=DISCOUNT_RATE&utm_source=dosiin&utm_medium=banner&utm_campaign=blackfriday&utm_content=brand4_codegraphy" target="_blank" rel="noopener noreferrer">
                        <img src="https://dosi-in.com/images/assets/musinsa/product-7.png" alt="product">
                        <div class="name-product">
                            CODEGRAPHY
                        </div> 
                        <div class="percent">
                            ~33%
                        </div>
                    </a>
                </div>
                <div>
                    <a href="https://global.musinsa.com/vn/brands/standoil?sortCode=DISCOUNT_RATE&utm_source=dosiin&utm_medium=banner&utm_campaign=blackfriday&utm_content=brand7_standoil" target="_blank" rel="noopener noreferrer">
                        <img src="https://dosi-in.com/images/assets/musinsa/product-8.png" alt="product">
                        <div class="name-product">
                            STAND OIL
                        </div> 
                        <div class="percent">
                            ~30%
                        </div>
                    </a>
                </div>
            </div>
            <div class="shop-now">
                <a href="https://globalmusinsaapp.onelink.me/8oYc/ypr8caul">MUA SẮM NGAY </a>
            </div>

        </section>

        <section class="musinsa_download">
            <a href="https://globalmusinsaapp.onelink.me/8oYc/4avwygg2" target="_blank">
                <picture>
                    <source media="(max-width: 650px)" srcset="https://dosi-in.com/images/assets/musinsa/download-mb.png">
                    <img src="https://dosi-in.com/images/assets/musinsa/download.png" alt="banner" width="100%" style="display:block;width: auto;margin: auto;">
                </picture>
            </a>
        </section>
    </div>
</template>

<style scoped>
    @import url('https://fonts.googleapis.com');
    @import url('https://fonts.gstatic.com');
    @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
    
    .musinsa_about,.musinsa_product{
        background-color: #000;
        
    }
    .musinsa_about-max-w{
        max-width: 643px;
        margin: 0 auto;
        text-align: center;
    }
    .musinsa_about h3{
        margin: 0;
        padding-top: 104px;
        font-family: 'Noto Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 150%;
        letter-spacing: -0.011em;
        text-transform: uppercase;
        color: #FFFFFF;
    }
    .musinsa_about p{
        padding-top: 30px;
        margin: 0;
        font-family: 'Noto Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.011em;
        color: #B7B7B7;
    }
    .musinsa_about h2{
        margin: 0;
        padding: 70px 0 44px 0;
        font-family: 'Noto Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 38px;
        line-height: 150%;
        letter-spacing: -0.011em;
        text-transform: uppercase;

        color: #FFFFFF;
    }
    .musinsa_product{
        text-align: center;
    }
    .musinsa_content{
        max-width: 780px;
        margin: 0 auto;

        font-family: 'Noto Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.011em;

        color: #B7B7B7;
    }
    .content-1{
        padding-top: 47px;
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        /* or 30px */

        text-align: center;
        letter-spacing: -0.011em;

        color: #B7B7B7;
    }
    .content-2{
        padding-top: 20px;
        padding-bottom: 55px;
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 150%;
        /* or 60px */

        letter-spacing: -0.011em;
        text-transform: uppercase;

        color: #FFFFFF;

    }
    .name-product{
        font-family: 'Noto Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        /* or 30px */

        text-align: center;
        letter-spacing: -0.011em;
        text-transform: uppercase;

        color: #FFFFFF;
        margin-top: 10px;
    }
    .percent{
        font-family: 'Noto Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
        /* identical to box height, or 36px */

        text-align: center;
        letter-spacing: -0.011em;
        text-transform: uppercase;

        color: #03FF6C;
    }
    .musinsa_product-list{
        list-style-type: none;
        max-width: 1000px;
        margin: auto;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: auto auto;
        grid-gap: 8px;  
    }
    .musinsa_product-list a{
        text-decoration: none;
    }
    .shop-now{
        padding: 39px 0;
    }
    .shop-now a{
        padding: 18px 220px;
        font-family: 'Noto Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        /* identical to box height, or 27px */

        text-align: center;
        letter-spacing: -0.011em;
        text-decoration: none;

        color: #FFFFFF;

        border: 1px solid #FFFFFF;
    }
    @media screen and (max-width: 640px) {
        .musinsa_about-max-w{
            padding: 0 35px;
        }
        .musinsa_about h3{
            padding-top: 55px;
            font-size: 28px;
            padding-bottom: 76px;
        }
        .musinsa_about p{
            font-size: 14px;
        }
        .musinsa_about h2{
            font-size: 28px;
            padding: 70px 0 20px 0;
        }
        .musinsa_content{
            padding: 0 16px;
        }
        .content-1{
            padding-top: 31px;
            font-size: 18px;
        }
        .content-2{
            padding-bottom: 31px;
            font-size: 22px;
        }
        .shop-now a{
            display: block;
            padding: 18px 0;
            margin: 16px;
        }
        .musinsa-page img{
            max-width: 100% ;
        }
        .musinsa_product-list{
            max-width: unset;
            grid-template-columns: auto auto;
    
        }
        .musinsa_product-list img {
            width: 100%;
            max-width: 176px;
            height: auto;
        }
        .name-product,.percent{
            font-size: 16px;
            line-height: 116%;
        }
        .musinsa_download {
            margin-bottom: 20px;
        }
    }
</style>